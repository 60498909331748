body {
  background: white;
  font-size: 14px;
  margin: 20px;
  margin: 0;
  padding: 0;
}

* {
  font-size: 14px;
}

button {
  margin: 4px;
  border: none;
  padding: 4px 8px;
  border-radius: 6px;
}
button.selected {
  background: olivedrab;
}
button.blue {
  background: cornflowerblue;
}
button.red {
  background: tomato;
}
button.yellow {
  background: yellow
}

input, textarea {
  width: 90%;
  margin-top: 10px;
  padding: 8px;
}

hr {
  border-top: solid 1px black;
  height: 0px;
}

div.box {
  border: solid 1px gray;
  padding: 8px;
  font-size: 13px;
  font-family:monospace;
  background: silver;
}



.btn_dropdown { 
  position: relative; 
  display: flex; 
  height: 40px !important; 
  margin: 0 8px; 
  padding: 0 8px; 
  border-radius: 7px; 
  align-items: center; 
  background-color: #1D242E; 
  color: #fff; 
  border: 1px solid #141920;
  pointer-events: all;
  cursor: pointer;
}
.btn_dropdown_label {
  min-width: 48px;
}
.btn_dropdown_list {
  position: absolute;
  top: 42px;
  left: 0;
  width: 100%;
  pointer-events: all;
  background: var(--unnamed-color-1d242e) 0% 0% no-repeat padding-box;
  background: #1D242E 0% 0% no-repeat padding-box;
  border: 1px solid #141920;
  border-radius: 6px;
  padding: 4px;
}
.btn_dropdown_list ul {
  max-height: 150px;
  overflow: hidden;
  overflow-y: auto;
}
.btn_dropdown_list li {
  padding-left: 4px;
  height: 31px;
  line-height: 31px;
}
.btn_dropdown_list li.selected {
  background: var(--unnamed-color-2f3744) 0% 0% no-repeat padding-box;
  background: #2F3744 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font-weight: bold;
}
.btn_dropdown_list li:hover {
  background: var(--unnamed-color-2f3744) 0% 0% no-repeat padding-box;
  background: #2F3744 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

.layer40 {
  z-index: 40;
}